import Success from "../assets/images/success.svg";
import ArrowBack from  "../assets/images/arrow-back.svg";
import Empty from "../assets/images/empty.svg";
import NotFound from "../assets/images/404.svg";
import Logout from "../assets/images/logout.svg";
import Logo from "../assets/images/logo.jpeg";
import Report from "../assets/images/location-marker.png";
import Waste from "../assets/images/waste-img.png";
import Map from "../assets/images/map.png";
import StreetWaste from "../assets/images/street-littering.webp";
import Brand from "../assets/images/brand.png";
import Hazardous from "../assets/images/hazardous.png";
import Construction from "../assets/images/construction.png";
import Sewage from "../assets/images/sewage.png";
import Street from "../assets/images/street-litter.png";
import Solid from "../assets/images/solid.png";
import StreetWasteBg from "../assets/images/street-waste.jpg";
import Lagos from "../assets/images/lagos.png";
import Lawma from "../assets/images/lawma.png";


export const ISuccess = Success;
export const IArrowBack = ArrowBack; 
export const IEmpty = Empty;
export const INotFound = NotFound;
export const ILogout = Logout;
export const ILogo = Logo;
export const ILocation = Report;
export const IWaste = Waste;
export const IMap = Map;
export const IStreetWaste = StreetWaste;
export const IBrand = Brand;
export const IHazardous = Hazardous;
export const IConstruction = Construction;
export const ISewage = Sewage;
export const IStreet = Street;
export const ISolid = Solid;
export const IStreetWasteBg = StreetWasteBg;
export const ILagos = Lagos;
export const ILawma = Lawma;
